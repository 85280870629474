import { useLocalization } from "@shared-ui/localization-context";
import { closeDialog, QueryRoute, updateSearch } from "bernie-core";
import { Layer } from "bernie-view";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "bernie-context";
import { when } from "mobx";
import { inject, MobXProviderContext, observer } from "mobx-react";
import * as React from "react";
import { DateFieldConfig } from "src/stores/wizard/config";
import { CarWizardState, HotelWizardState, LxWizardState } from "src/stores/wizard/state";
import { FlightWizardState } from "src/stores/wizard/state/flight";
import { DateState } from "src/stores/wizard/state/global";
import { PackageWizardState } from "src/stores/wizard/state/package";
import { DayName, EGDSDatePicker } from "@egds/react-core/date-picker";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { ACTION_DONE, ACTION_OPEN, getTracker } from "utils/TrackingUtils";
import { CarSubLOBs } from "../../../stores/wizard/state/typings";
import { DatesProps } from "./typings";

/**
 * Using configuration for localized datePicker options:
 *  eg. cutoffDate configuration option:
 *      1. create override file using siteId in name: src/stores/wizard/config/overrideConfigBySiteId/siteIds/siteIdXXXX.ts
 *      2. modify index file located in src/stores/wizard/config/overrideConfigBySiteId/siteIds/index.ts
 *         to include export for this siteId adding line like this:
 *             export { siteIdXXXX } from "stores/wizard/config/overrideConfigBySiteId/siteIds/siteIdXXXX";
 *      3. modify src/stores/wizard/config/overrideConfigBySiteId/overrideConfigBySiteId.ts
 *        3.1 import to include your siteId (siteIdXXXX)
 *        3.2 specific export config (may include more that one override) for your siteIds (XXXX)
 *          XXXX: {
 *            default: siteIdXXXX,
 *          },
 *
 */
export const Dates: React.FunctionComponent<DatesProps> = inject("analytics")(
  observer(
    ({
      singleDate,
      singleDateSelect,
      colSpan,
      colStart,
      colEnd,
      lobState,
      isPartialStay,
      multiLegIndex,
      additionalSingleDate,
      stackedDates,
      startId,
      endId,
      analytics,
      setInputsRefs,
      autoOpenCalendar,
      toggleDatePicker,
      useHiddenInputs = true,
      datePickerContent,
      footerText,
      isDesktop,
      htgStartInvalidMessage,
      htgEndInvalidMessage,
      validateAndGetFormErrors,
      submitForm,
      hasEmptyDates,
      validStartDateOffset = 0,
    }) => {
      const location = useLocation();
      const history = useHistory();
      const context: Context = React.useContext(MobXProviderContext).context;
      const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false);
      const initialDaysBookableInAdvance = React.useRef<number | null>(null);
      const { formatText, formatDateString, formatDate, getWeekData } = useLocalization();
      const hotelWizardState = lobState instanceof HotelWizardState ? lobState : undefined;
      const flightWizardState = lobState instanceof FlightWizardState ? lobState : undefined;
      const [dateConfig, updateDates, dateState, dateStartInvalidKey, dateEndInvalidKey] = ((): [
        DateFieldConfig,
        (start: Date, end: Date, multiLegIndex?: number, additionalSingleDate?: boolean) => void,
        DateState,
        string,
        string
      ] => {
        if (!multiLegIndex && isPartialStay && lobState instanceof PackageWizardState) {
          return [
            lobState.config.partialStayDate,
            lobState.updatePartialStayDateSelection,
            lobState.partialStayDate,
            lobState.partialStayStartDateInvalidKey,
            lobState.partialStayEndDateInvalidKey,
          ];
        }
        if (lobState instanceof PackageWizardState && lobState.subLOBState === "hc") {
          return [
            lobState.config.subLOB.hotelCar.date,
            lobState.updateDateSelection,
            lobState.date,
            lobState.dateStartInvalidKey,
            lobState.dateEndInvalidKey,
          ];
        }
        if (isPartialStay && lobState instanceof FlightWizardState && lobState.config.hotelDate) {
          return [
            lobState.config.hotelDate,
            lobState.updateHotelDateSelection,
            lobState.hotelDate,
            lobState.hotelStartDateInvalidKey,
            lobState.hotelEndDateInvalidKey,
          ];
        }
        if (
          multiLegIndex !== undefined &&
          (lobState instanceof FlightWizardState || lobState instanceof PackageWizardState) &&
          lobState.config.multiCityFlight
        ) {
          const dateStartInvalidKeyString =
            lobState.legsDateStartInvalidKey.length > multiLegIndex
              ? lobState.legsDateStartInvalidKey[multiLegIndex] || ""
              : "";

          return [
            lobState.config.multiCityFlight.legs[multiLegIndex].date,
            lobState.updateLegDate,
            lobState.listOfAdditionalLegs[multiLegIndex].date,
            dateStartInvalidKeyString,
            "",
          ];
        }
        if (
          lobState instanceof CarWizardState &&
          lobState.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION &&
          additionalSingleDate
        ) {
          return [
            lobState.config.gtConfig.additionalSingleDate,
            lobState.updateAdditionalSingleDate,
            lobState.additionalSingleDate,
            lobState.dateEndInvalidKey,
            "",
          ];
        }
        if (lobState instanceof CarWizardState && lobState.subLOBState === CarSubLOBs.GROUND_TRANSPORTATION) {
          return [
            lobState.config.gtConfig.date,
            lobState.updateDateSelection,
            lobState.date,
            lobState.dateStartInvalidKey,
            "",
          ];
        }
        return [
          lobState.config.date,
          lobState.updateDateSelection,
          lobState.date,
          lobState.dateStartInvalidKey,
          lobState.dateEndInvalidKey || "",
        ];
      })();

      const onOpenCB = (name?: string, isMobile?: boolean) => {
        if (isMobile) {
          updateSearch({ history, location, newParams: { pwaDialog: pwaDialog } });
        }

        if (toggleDatePicker) {
          toggleDatePicker();
        } else {
          setShowDatePicker(true);
        }

        if (flightWizardState != undefined) {
          const type = name === "start" ? _departureDate : _arrivalDate;
          track(type + _selected);
        } else {
          const type = name === "start" ? _checkIn : _checkOut;
          track(`date.${ACTION_OPEN}.${type}`);
        }
      };

      const onSubmitCB = (start: Date, end: Date, isMobile: boolean) => {
        const newHotelWizardDates = {
          start: formatDateString(start, { raw: dateConfig.format }),
          end: formatDateString(end, { raw: dateConfig.format }),
        };

        if (toggleDatePicker) {
          toggleDatePicker();
        } else {
          setShowDatePicker(false);
        }

        if (!end) {
          if (lobState instanceof LxWizardState) {
            end = start;
          }
        }

        if (!isDesktop && start > end) {
          end.setDate(start.getDate() + 1);
        }

        if (start || end) {
          hotelWizardState?.setHtgStartDate(newHotelWizardDates.start);
          hotelWizardState?.setHtgEndDate(newHotelWizardDates.end);
        }

        // Enables the validator to show the error message when the end date is invalid
        if (start || end) {
          updateDates(start, end, multiLegIndex);
          when(() => {
            const isStartDateUpdated = hotelWizardState?.startDate === newHotelWizardDates.start;
            const isEndDateUpdated = hotelWizardState?.endDate === newHotelWizardDates.end;

            return isStartDateUpdated && isEndDateUpdated;
          }).then(submitForm);
        }

        const hasDate = Boolean(hotelWizardState?.startDate || hotelWizardState?.endDate);

        if (hasDate && validateAndGetFormErrors) {
          validateAndGetFormErrors();
        }

        if (flightWizardState != undefined) {
          track(_submit + _selected);
        } else {
          track(`dates.${ACTION_DONE}`);
        }
      };

      const onDismissCB = (isMobile: boolean) => {
        if (isMobile) {
          closeDialog({ history, location });
        }

        if (toggleDatePicker) {
          toggleDatePicker();
        } else {
          setShowDatePicker(false);
        }

        if (flightWizardState != undefined) {
          track(_dismiss + _selected);
        }
      };

      const pwaDialog = `datepicker-${dateConfig.dialogId}`;

      const routerLinkWrapper = (children: React.ReactNode) => <>{children}</>;

      const dialogWrapper = (children: React.ReactNode, dateType: string) => {
        return (
          <QueryRoute query={{ pwaDialog }}>
            <Layer id={`${pwaDialog}-${dateType}`} onDismiss={onDismissCB}>
              {children}
            </Layer>
          </QueryRoute>
        );
      };

      const getStartId = (defaultValue: string) => {
        return startId || defaultValue;
      };

      const getEndId = (defaultValue: string) => {
        return endId || defaultValue;
      };

      const onChangeStartDateCB = () => {
        if (flightWizardState != undefined) {
          track(_departureDate + _changed);
        } else {
          track(_selectDates + _checkIn);
        }
      };

      const onChangeEndDateCB = () => {
        if (flightWizardState != undefined) {
          track(_arrivalDate + _changed);
        } else {
          track(_selectDates + _checkOut);
        }
      };

      const tracker = getTracker(lobState.trackingEnabled, analytics, lobState.moduleName);

      const _checkIn = "check-in";
      const _checkOut = "check-out";
      const _selectDates = "dates.select.";
      const _partialStay = "hotel-partial-stay.";

      //Flights
      const _departureDate = "DEPARTUREDATE.";
      const _arrivalDate = "ARRIVALDATE.";
      const _oneWay = "OW";
      const _roundTrip = "RT";
      const _selected = "SELECTED.";
      const _changed = "CHANGED.";
      const _submit = "SUBMIT.";
      const _dismiss = "DISMISS.";

      const track = (action: string) => {
        if (flightWizardState != undefined) {
          const postfix = singleDate ? _oneWay : singleDateSelect ? _roundTrip : "";
          tracker.trackEvent(action + postfix);
        } else {
          const prefix = isPartialStay ? _partialStay : "";
          tracker.trackEvent(prefix + action);
        }
      };

      const dateFieldProps = {
        endId: getEndId("d2"), // TODO: uitk-new-date-picker not supports setting custom date value format.
        endInvalid: htgEndInvalidMessage ?? formatText(dateEndInvalidKey, dateConfig.maxDaysRange),
        endLabel: formatText(dateConfig.end.labelToken),
        endName: getEndId("d2"), // TODO: uitk-new-date-picker not supports setting custom date value format.
        endPlaceholder: hasEmptyDates
          ? formatText("datePicker.dateInput.placeholder")
          : formatText(dateConfig.end.labelToken),
        routerLinkWrapper,
        showDatePicker: typeof autoOpenCalendar === "boolean" ? autoOpenCalendar : showDatePicker,
        stacked: stackedDates,
        startId: getStartId("d1"), // TODO: uitk-new-date-picker not supports setting custom date value format.
        startInvalid: htgStartInvalidMessage ?? formatText(dateStartInvalidKey, dateConfig.maxDaysRange),
        startLabel: formatText(dateConfig.start.labelToken),
        startName: getStartId("d1"), // TODO: uitk-new-date-picker not supports setting custom date value format.
        startPlaceholder: hasEmptyDates
          ? formatText("datePicker.dateInput.placeholder")
          : formatText(dateConfig.start.labelToken),
        onChange: () => null, // error thrown if no onChange handler is passed
      };

      // Certain LOBs dont support ISO format for their SRPs they only support local format
      // If that is the case the local raw format will be taken from bernie context
      const rawFormatForSubmission = dateConfig.ISOSubmissionSupport ? dateConfig.format : context.localDateFormat;

      /**
       * Builds and returns a date starting
       * from tomorrow in the given format in the
       * rawFormatForSubmission as a string value.
       */
      const getStartValidDate = (startDate: Date, incrementDays: number, format: string): string => {
        const date = new Date();
        date.setDate(startDate.getDate() + incrementDays);
        return formatDate(date, { raw: format });
      };

      if (lobState instanceof HotelWizardState && (lobState.isAddACarChecked || lobState.isAddAFlightChecked)) {
        lobState.overrideConfig(() => {
          if (
            dateConfig.softPackage &&
            dateConfig.daysBookableInAdvance !== dateConfig.softPackage.daysBookableInAdvance
          ) {
            initialDaysBookableInAdvance.current = dateConfig.daysBookableInAdvance;
            dateConfig.daysBookableInAdvance = dateConfig.softPackage.daysBookableInAdvance;
          }
        });
      } else if (
        initialDaysBookableInAdvance.current !== null &&
        dateConfig.daysBookableInAdvance !== initialDaysBookableInAdvance.current
      ) {
        lobState.overrideConfig(() => {
          if (initialDaysBookableInAdvance.current !== null) {
            dateConfig.daysBookableInAdvance = initialDaysBookableInAdvance.current;
          }
        });
      }

      const isHotelsCars = lobState instanceof PackageWizardState && lobState.subLOBState === "hc";
      const validStartDate = getStartValidDate(
        new Date(),
        isHotelsCars ? 1 : validStartDateOffset,
        rawFormatForSubmission
      );

      // @ts-ignore
      const firstDayOfWeek = parseInt(DayName[getWeekData().firstDay.toUpperCase()], 10);

      //The first div is the one that adds the UitkLayoutGridItem classes.
      //The second div with the class Dates was added so that custom classes work as expected without using !important in them.

      return (
        <EGDSLayoutGridItem colSpan={colSpan} colStart={colStart} colEnd={colEnd}>
          <div>
            <div className="Dates">
              {/*
              TODO: uitk-new-date-picker not supports setting custom date value format.
              Add start/end hidden inputs with custom value format.
            */}
              {useHiddenInputs && (
                <>
                  <input
                    type="hidden"
                    data-testid="dates-startDate"
                    name={dateConfig.queryParam.start}
                    value={
                      formatDateString(dateState.start, { raw: rawFormatForSubmission }) ||
                      formatDateString(dateState.start, { raw: dateConfig.format })
                    }
                  />
                  {!singleDate && (
                    <input
                      type="hidden"
                      data-testid="dates-endDate"
                      name={dateConfig.queryParam.end}
                      value={
                        formatDateString(dateState.end, { raw: rawFormatForSubmission }) ||
                        formatDateString(dateState.end, { raw: dateConfig.format })
                      }
                    />
                  )}
                </>
              )}
              <EGDSDatePicker
                cutoffDate={dateConfig.cutoffDate}
                dateFieldProps={dateFieldProps}
                dialogWrapper={dialogWrapper}
                daysBookableInAdvance={dateConfig.daysBookableInAdvance}
                doubleMonths
                endDate={singleDate || hasEmptyDates ? "" : formatDateString(dateState.end, { raw: dateConfig.format })}
                firstDayOfWeek={firstDayOfWeek || DayName.SUN}
                midnightBookingOffset={dateConfig.midnightBookingOffset}
                onDismissCB={onDismissCB}
                onOpenCB={onOpenCB}
                onSubmitCB={onSubmitCB}
                onChangeStartDateCB={onChangeStartDateCB}
                onChangeEndDateCB={onChangeEndDateCB}
                startDate={hasEmptyDates ? "" : formatDateString(dateState.start, { raw: dateConfig.format })}
                singleMode={singleDate}
                onMountCB={setInputsRefs}
                singleDateSelect={singleDateSelect}
                validStartDate={validStartDate}
                datePickerContent={datePickerContent}
                footerText={footerText}
              />
            </div>
          </div>
        </EGDSLayoutGridItem>
      );
    }
  )
);
